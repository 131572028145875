import React from 'react';
import ErrorPageLogo from '@/components/ErrorPageLogo';

const NotFoundPage = () => (
  <ErrorPageLogo
    logoUrl="/notFoundPageLogo.svg"
    title="Oops, something's not right!"
    message="This page doesn't exist or was removed!"
  />
);

export default NotFoundPage;
