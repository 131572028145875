import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Image,
  Text,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { ERROR_PAGE_BACKGROUND_COLOR } from '@/utils/colorUtils';
import Header from '@/components/Header/Header';

const ErrorPageLogo = ({ logoUrl, title, message }) => {
  const bg = useColorModeValue(
    ERROR_PAGE_BACKGROUND_COLOR.light,
    ERROR_PAGE_BACKGROUND_COLOR.dark,
  );

  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <>
      <Header showMenu={false} isInErrorPage />
      <Flex
        justify="center"
        align="center"
        direction="column"
        h="100vh"
        backgroundColor={bg}
      >
        <Image src={logoUrl} />
        <Flex
          mt="2"
          direction="column"
          alignItems="center"
          cursor="default"
          color={textColor}
        >
          <Heading>{title}</Heading>
          <Text mt="2">{message}</Text>
        </Flex>
      </Flex>
    </>
  );
};

ErrorPageLogo.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorPageLogo;
